import { useState } from 'react';
import { Box, Button, Container, DialogContent, DialogTitle, Grid, IconButton, Stack, TextField, Typography, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitleProps } from '../../../interfaces/DialogTitleProps';
import Home from '../../../assets/icons/shipping_home.svg';
import { AxiosResponse } from 'axios';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import PrimaryButton from '../../../core/buttons/primary-button';
import Patient from '../../../constants/grx-api';
import { UPDATE_EXPECTED_DATE } from '../../../constants/Endpoints';
import { toast } from 'react-toastify';
import { formatMessage } from '../../../constants/util';
import holidaysData from '../../../JSON/holidays.json';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const CalanderContainer = (props: { className?: string; children: any }) => {
  return (
    <Box className={props?.className} sx={{ backgroundColor: '#fffff' }}>
      <div style={{ position: 'relative', backgroundColor: '#fffff !important', opacity: 'none' }}>{props.children}</div>
    </Box>
  );
};

export const UpdateOrderDeliveryDate = (props: { handleClose: (res?: boolean) => void; orderDate: Date; orderId: Number }) => {
  const [selectedDate, setSelectedDate] = useState(props.orderDate);

  const handleUpdateDeliveryDate = async () => {
    try {
      const data = {
        orderId: props.orderId,
        expectedBy: selectedDate
      };
      const response = await Patient.post(UPDATE_EXPECTED_DATE, data);
      if (response.status === 201) {
        props.handleClose(true);
      }
    } catch (error: any) {
      if (error.response.data.message) {
        toast(formatMessage(error.response.data.message));
        return;
      }
    }
  };

  const onDateChange = (date: any) => {
    console.log(date)
    setSelectedDate((prev) => date);
  };

  function parseDateUTC(dateString: string | Date) {
    console.log(dateString);
  
    if (typeof dateString === 'string') {
      const parts = dateString.split('-');
      const year = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10); // Months are zero-indexed
      const day = parseInt(parts[2], 10);
  
      // Construct the date in UTC explicitly
      const dateObj = new Date(Date.UTC(year, month - 1, day));
  
      return dateObj;
    }
    
    return dateString;
  }
  
  const holidays = holidaysData.holidays;

  // Function to add business days skipping weekends
  // const addDaysSkippingWeekends = (date: any, daysToAdd: any, holidays) => {
  //   let result = new Date(date);
  //   let addedDays = 0;

  //   while (addedDays < daysToAdd) {
  //     result.setDate(result.getDate() + 1);
  //     // Skip Saturday (6) and Sunday (0)
  //     if (result.getDay() !== 0 && result.getDay() !== 6) {
  //       addedDays++;
  //     }
  //   }
  //   return result;
  // };

  console.log(holidays)

  const addDaysSkippingWeekends = (date: Date, daysToAdd: number, holidays: string[]): Date => {
    let result = new Date(date);
    let addedDays = 0;

    while (addedDays < daysToAdd) {
        result.setDate(result.getDate() + 1);  // Increment the day by 1

        const year = result.getFullYear();
        const month = result.getMonth() + 1;  // Months are 0-based in JS
        const dayOfMonth = result.getDate();

        // Convert holidays to a Set for efficient lookups (same format as "YYYY-MM-DD")
        const isHoliday = holidays.some((holiday) => {
            const [holidayYear, holidayMonth, holidayDay] = holiday.split('-');
            return (
                parseInt(holidayYear, 10) === year &&  // Match the year
                parseInt(holidayMonth, 10) === month && // Match the month
                parseInt(holidayDay, 10) === dayOfMonth // Match the day
            );
        });

        // Skip weekends (Saturday = 6, Sunday = 0) and holidays
        if (result.getDay() !== 0 && result.getDay() !== 6 && !isHoliday) {
            addedDays++;
      }
      
      console.log(result.getDay() !== 0 , result.getDay() !== 6 , !isHoliday , addedDays)
    }

    return result;
};



  return (
    <>
      <DialogContent dividers className="popup_content">
        <Box component="main" className="card-info">
          <Container maxWidth="lg">
            <Box className="main-box" mt={4}>
              <Box sx={{ bgcolor: 'background.paper' }}>
                <Stack className="modal_heading_main" direction="row" justifyContent="center" alignItems="center" mt={3}>
                  <Typography className="heading_bottom_without_border">
                    {/* <span className="profile_icon">
                      <img src={Home} alt="Imprimis RX A Harrow Company" width={16} />
                    </span> */}
                    Update Order Expected Date
                  </Typography>
                </Stack>
                <Box>
                  <Grid container spacing={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Grid item xl={10} lg={10} md={10} sm={10} xs={10} mt={1} display={'flex'} justifyContent={'center'}>
                      <DatePicker
                        selected={parseDateUTC(selectedDate)}
                        onChange={(date) => onDateChange(date)}
                        className="centered-text"
                        closeOnScroll={true}
                        dateFormat="MM-dd-yyyy"
                        minDate={addDaysSkippingWeekends(new Date(), 8, holidays)} // Use function to skip weekends and set minDate
                        calendarContainer={CalanderContainer}
                        showMonthDropdown
                        dropdownMode="select"
                        filterDate={(date) => {
                          const day = date.getDay();
                          const isWeekend = day === 0 || day === 6; // Check for weekends

                          // Extract month and date from the given date
                          const year = date.getFullYear();
                          const month = date.getMonth() + 1; // Months are 0-based in JS
                          const dayOfMonth = date.getDate();

                          // Check if this month/date matches any holiday
                          const isHoliday = holidays.some((holiday) => {
                            const [holidayYear, holidayMonth, holidayDay] = holiday.split('-');
                            return parseInt(holidayYear, 10) === year && parseInt(holidayMonth, 10) === month && parseInt(holidayDay, 10) === dayOfMonth;
                          });

                          return !isWeekend && !isHoliday; // Only allow weekdays and non-holidays
                        }}
                        showYearDropdown
                        customInput={
                          <InputMask mask="99-99-9999" maskChar={null} value={new Date(props.orderDate).toDateString()} onChange={(e: any) => onDateChange(e)} disabled={false}>
                            {(inputProps: any) => (
                              <TextField
                                {...inputProps}
                                label="Please select date"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <CalendarMonthIcon />
                                    </InputAdornment>
                                  )
                                }}
                                InputLabelProps={{ required: true }}
                                inputProps={{
                                  ...inputProps.inputProps,
                                  autoComplete: 'new-password'
                                }}
                                // disabled={false}
                                fullWidth
                              />
                            )}
                          </InputMask>
                        }
                        wrapperClassName="date-picker-wrapper form-input" // Add wrapper class if needed
                        popperPlacement="bottom" // Customize popper placement if needed
                      // inline
                      />
                    </Grid>
                    <Grid item xl={10} lg={10} md={10} sm={10} xs={10} mt={1} display={'flex'} justifyContent={'center'}>
                      <PrimaryButton label={'Update Date'} onClick={handleUpdateDeliveryDate} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} display={'flex'} justifyContent={'center'}>
                    <Grid item xl={10} lg={10} md={10} sm={10} xs={10} mt={1} display={'flex'} justifyContent={'center'}>
                      <Typography variant="subtitle2">
                        Please chose a date you expect your medication by. If no date is chosen, it will default to 7 business days from today. Processing time is based on a complete prescription received by the physician. If you need shipping earlier than 7 days, please chat with us using the
                        orange button below.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </DialogContent>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={() => props.handleClose(false)}></BootstrapDialogTitle>
    </>
  );
};
