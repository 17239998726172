import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AxiosResponse } from 'axios';
import Back from '../../../assets/icons/back_arrow.svg';
import Chat from '../../../assets/icons/chat.svg';
import Dialog from '@mui/material/Dialog';
import Doctor from '../../../constants/grx-api';
import { GET_PATIENT_ORDER_DETAIL, GET_PATIENT_ORDERS, GET_PATIENT_CARDS } from '../../../constants/Endpoints';
import { User } from '../../../models/User';
import Header from '../../../components/header/header';
import Ordersvg from '../../../assets/icons/order.svg';
import { OrderInterface } from '../../../interfaces/order';
import Paper from '@mui/material/Paper';
import Pdf from '../../../assets/icons/view-pdf.svg';
import PrimaryButton from '../../../core/buttons/primary-button';
import Rx from '../../../assets/icons/RX.svg';
import Rx_icon from '../../../assets/icons/rx_icon.svg';
import SecondaryButton from '../../../core/buttons/secondary-button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Usersvg from '../../../assets/icons/user.svg';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch, useAppSelector } from '../../../store';
import { Order } from '../../../models/Order';
import { dashboardActions, patientActions } from '../../../store/Actions';
import GeneratePdf from './GeneratePdf';
import Patient from '../../../constants/grx-api';
import { payOrderHandler } from '../../../services/components/payInvoice.service';
import { EditOrderShippingAddress } from '../../../components/popup/EditOrderShippingAddress';
import { addNewShippingAddressForm } from '../../../services/pages/popup-form/AddNewShippingAddressForm.service';
import { formatMessage } from '../../../constants/util';
import { isLoggedIn } from '../../../services/auth/auth.service';
import { AddNewCard } from '../../../components/popup/AddNewCard';
import { RequestRefill } from '../../../components/popup/RequestRefill';
import { AutoEnrollRefill } from '../../../components/popup/EnrollInAutoRefill';
import { MessagePopup } from '../../../components/popup/MessagePopup';
import { RequestRefillDetail } from '../../../components/popup/RequestRefillDetail';
import { OrderDeliveryDate } from '../../../components/popup/AddExpectedDeliveryDate';
import { UpdateOrderDeliveryDate } from '../../../components/popup/UpdateOrderDeliveryDate';
import PayInfo from '../../../components/popup/DeliveryPaymentInfo';
import PayOrder from '../../../components/popup/PayOrder';
import placeHolder from '../../../assets/placeHolder.png';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textAlign: 'center'
  },
  [`&.${tableCellClasses.head}:first-child`]: {
    textAlign: 'left !important' // Align only the first cell item of the header to the left
  },
  [`&.${tableCellClasses.body}:first-child`]: {
    textAlign: 'left !important' // Align only the first cell item of the header to the left
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

interface ShippingAddress {
  address1: any;
  address2: any;
  city: any;
  state: any;
  zipCode: any;
}

const OrderPage = () => {
  const params = useParams();
  const location = useLocation();
  const router = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoaded, setIsLoaded] = useState(true);
  const [orderFrom, setOrderFrom] = useState<string>(location.state.data);
  const [order, setOrder] = useState<Order | undefined>();
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [model, setModel] = useState<{ editAddress: boolean; addCard: boolean; requestRefill: boolean; autoRefill: boolean; message: boolean; payOrder: boolean; orderDate: boolean; refillAddress: boolean; payInfo: boolean }>({
    editAddress: false,
    addCard: false,
    requestRefill: false,
    autoRefill: false,
    message: false,
    orderDate: false,
    refillAddress: false,
    payInfo: false,
    payOrder: false
  });
  // const [shippingAddress, setShippingAdrress] = useState<any>([]);
  const [unEnrolledIds, setUnEnrolledIds] = useState<number[]>([]);
  const [selectedDate, setSelectedDate] = useState<String>('');

  const componentRef = useRef(null);
  const user: User = useAppSelector((state) => state.profileReducer.user);
  const unpaidOrders: Order[] = useAppSelector((state) => state.dashboardReducer.unpaidOrders);
  const orders: Order[] = useAppSelector((state) => state.dashboardReducer.orders);
  const [orderId, setOrderId] = useState<number>(0); // to update shipping address and expected date
  const [rxId, setRxId] = useState<number[]>([]);
  const [originalShippingAddress, setOriginalShippingAddress] = useState<ShippingAddress>({
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: ''
  });

  const closeModel = () => {
    setModel((prev) => ({ ...prev, autoRefill: false, requestRefill: false }));
  };
  const handlePrescriptionHistory = () => {
    // router("/home/prescriptions");
    dispatch(patientActions.setRedirectToPatientPreHistory(true));
    window.history.back();
  };

  const setShippingAddress = (order: Order) => {
    addNewShippingAddressForm.patchValue({
      street: order.shippingAddress.address1,
      apt_suite: order.shippingAddress.address2,
      city: order.shippingAddress.city,
      state: order.shippingAddress.state,
      code: order.shippingAddress.zipCode,
      address: order.shippingAddress.address1
    });
  };
  const handleEnrollAutoRefill = async (order: Order) => {
    setModel((prevModel) => ({
      ...prevModel,
      autoRefill: true
    }));
  };
  const handleRequestRefill = () => {
    setShippingAddress(order as Order);

    setModel((prevModel) => ({
      ...prevModel,
      requestRefill: true
    }));
  };

  const handleRequestRefillClose = (res?: boolean) => {
    closeModel();
  };

  const handleModelClose = () => {
    setModel((oldData) => ({
      ...oldData,
      editAddress: false,
      addCard: false
    }));
  };

  const handleEditOrderAddress = () => {
    setShippingAddress(order as Order);
    setModel((oldData) => ({
      ...oldData,
      editAddress: true
    }));
  };

  const handleEditOrderAddressClose = (res?: boolean) => {
    handleModelClose();
    if (res === true && orderFrom === 'orders') {
      getOrder(params.id);
    }
  };

  const handleAddCardClose = () => {
    handleModelClose();
  };

  const handlePayInvoice = async (order: Order) => {


    setModel((prev) => ({ ...prev, payOrder: true }));
  };



  const printPdf = useReactToPrint({
    content: () => componentRef.current
  });

  // print invoice handler
  const handlePrintPdf = () => {
    if (componentRef.current) {
      printPdf();
    }
  };

  // get all orders
  const getOrder = async (orderId?: string) => {
    try {
      const res: AxiosResponse = await Patient.get(`${GET_PATIENT_ORDER_DETAIL}/${orderId}`);
      if (res.status === 200) {
        setOrder(res.data);
        setOriginalShippingAddress(res.data?.shippingAddress);
      }

      const newPrescriptions = res.data.prescriptions?.map((prescription: any) => prescription.rxId) || [];
      setRxId(newPrescriptions);
    } catch (err: any) {
      if (err?.response?.data?.message) {
        toast(formatMessage(err?.response.data.message));
        return;
      }
    }
  };

  useEffect(() => {
    if (orderFrom === 'orders') {
      getOrder(params.id);
    }
  }, [orderFrom, orders, unpaidOrders]);

  useEffect(() => {
    if (!isLoggedIn()) {
      router('/');
    } else {
      setIsLoaded(true);
    }
  }, [router]);

  // Thousands Separator
  const thousandsSaparator = (x: any) => {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(() => {
    if (params?.id !== undefined) {
      setOrderId(parseInt(params.id));
    }
  }, [params?.id]);

  const handleEnrollAutoRefillClose = (res?: boolean) => {
    if (res) {
      getOrder(params.id);
    }
    closeModel();
  };

  const handleOpen = () => {
    setModel((oldData) => ({
      ...oldData,
      message: true
    }));
  };

  const handleClose = () => {
    setModel((oldData) => ({
      ...oldData,
      message: false
    }));
  };

  const handleCloseModel = () => {
    setModel((oldData) => ({
      ...oldData,
      message: false
    }));
  };

  const handleDeliveryDate = (res: Array<number>) => {
    setUnEnrolledIds(res);

    setModel((oldData) => ({
      ...oldData,
      orderDate: true,
      requestRefill: false
    }));
  };

  const handleOpenDeliveryAddress = (res?: String) => {
    if (res) {
      setSelectedDate(res);
    }
    setModel((oldData) => ({
      ...oldData,
      refillAddress: true
    }));
  };

  const handleOpenPay = () => {
    setModel((oldData) => ({
      ...oldData,
      payInfo: true
    }));
  };

  const handleCloseDateModel = () => {
    setModel((oldData) => ({
      ...oldData,
      orderDate: false
    }));
  };

  const handleRefillAddressClose = () => {
    setModel((oldData) => ({
      ...oldData,
      refillAddress: false
    }));
  };

  const handlePayClose = () => {
    setModel((oldData) => ({
      ...oldData,
      payInfo: false,
      payOrder: false,
      requestRefill: false
    }));
  };

  const openPayInfo = () => {
    setModel((oldData) => ({
      ...oldData,
      payInfo: true
    }));
  };


  const openOrderPay = () => {
    setModel((oldData) => ({
      ...oldData,
      payOrder: true
    }));
  };

  return isLoaded ? (
    <>
      <Stack component="main" className="default-layout">
        <Header />
        <Box>
          {model.payInfo && (
            <BootstrapDialog onClose={() => handlePayClose()} open={model.payInfo} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
              <PayInfo handleClose={(res?: boolean) => handlePayClose()} openPayInfo={openPayInfo} selectedDate={selectedDate} orderId={Number(params?.id)} unEnrolledIds={unEnrolledIds} originalShippingAddress={originalShippingAddress} handleOpen={handleOpen} />
            </BootstrapDialog>
          )}

          {model.payOrder && (
            <BootstrapDialog onClose={() => handlePayClose()} open={model.payOrder} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
              <PayOrder handleClose={(res?: boolean) => handlePayClose()} openPayInfo={openOrderPay} orderId={Number(params?.id)} />
            </BootstrapDialog>
          )}

          {model.orderDate && (
            <BootstrapDialog onClose={() => handleCloseDateModel()} open={model.orderDate} PaperProps={{ style: { height: 'auto', minHeight: 500, width: 360, overflowX: 'auto' } }}>
              <OrderDeliveryDate handleClose={(res?: boolean) => handleCloseDateModel()} orderId={Number(params?.id)} handleOpenDeliveryAddress={(res?: String) => handleOpenDeliveryAddress(res)} />
            </BootstrapDialog>
          )}
          {model.message && (
            <BootstrapDialog onClose={() => handleCloseModel()} open={model.message} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
              <MessagePopup handleClose={(res?: boolean) => handleCloseModel()} content="Your request has been sent in auto refill, please contact Customer Service for any changes." />
            </BootstrapDialog>
          )}
          {model?.addCard && (
            <BootstrapDialog open={model?.addCard} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
              <AddNewCard handleClose={handleAddCardClose} />
            </BootstrapDialog>
          )}
          {model.editAddress && params.id && (
            <BootstrapDialog onClose={() => handleEditOrderAddressClose()} open={model.editAddress} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
              <EditOrderShippingAddress handleClose={(res?: boolean) => handleEditOrderAddressClose(res)} orderId={Number(params?.id)} />
            </BootstrapDialog>
          )}
          {model.autoRefill && (
            <BootstrapDialog onClose={() => handleEnrollAutoRefillClose()} open={model.autoRefill} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
              <AutoEnrollRefill handleClose={(res?: boolean) => handleEnrollAutoRefillClose(res)} orderId={Number(params?.id)} />
            </BootstrapDialog>
          )}
          {model.refillAddress && (
            <BootstrapDialog onClose={() => handleRefillAddressClose()} open={model.refillAddress} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
              <RequestRefill handleClose={(res?: boolean) => handleRefillAddressClose()} handleOpen={(res?: boolean) => handleOpenPay()} orderId={Number(params?.id)} rxId={rxId} originalShippingAddress={originalShippingAddress} />
            </BootstrapDialog>
          )}
          {model.requestRefill && (
            <BootstrapDialog onClose={() => handleRequestRefillClose()} open={model.requestRefill} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
              <RequestRefillDetail handleClose={(res?: boolean) => handleRequestRefillClose(res)} handleDeliveryDate={(res: Array<number>) => handleDeliveryDate(res)} orderId={Number(params?.id)} />
            </BootstrapDialog>
          )}
          <Box component="main" className="genral_order">
            <Box className="main-content-wrapper-full">
              <Container maxWidth="xl">
                <Box className="main-box">
                  <Container maxWidth="xl">
                    {/*------ ORDER GENTRAL TOP BACK ------*/}
                    <Box className="genral_top_container">
                      <Box className="genral_top" onClick={handlePrescriptionHistory}>
                        <span className="back_arrow">
                          <img src={Back} alt="Imprimis RX A Harrow Company" />
                        </span>
                        Back to Prescriptions
                      </Box>
                      <Box className="re_order_pdf_btn">
                        <ul>
                          <li>
                            <Button
                              className="contact_btn"
                              variant="contained"
                              onClick={handlePrintPdf}
                              style={{ fontSize: '17px', height: '46px', width: '218px', fontWeight: '600', backgroundColor: '#00ACBA', border: '1px solid #00ACBA', borderRadius: '8px', boxShadow: 'none', textTransform: 'capitalize' }}
                            >
                              Download PDF{' '}
                              <Box ml={1}>
                                <img src={Pdf} alt="input_icon" width={15} />
                              </Box>
                            </Button>
                          </li>
                        </ul>
                      </Box>
                    </Box>
                    {/*------ ORDER GENTRAL MAIN ------*/}

                    <Box className="order_general">
                      <Box>
                        <Stack direction="row">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={5}>
                              <Box className="rx_img">
                                <img src={Rx} alt="Imprimis RX A Harrow Company" />
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={7}>
                              <Box className="order_general_info">
                                <Box className="order_general_heading_container">
                                  <Typography className="heading">
                                    <span className="profile_icon">
                                      <img src={Ordersvg} alt="Imprimis RX A Harrow Company" width={16} />
                                    </span>
                                    <span className="profile_icon_heading"> Prescription Info </span>
                                  </Typography>
                                </Box>
                                <Stack direction="row">
                                  <Grid container className="order_general_row order_general_row_top">
                                    <Grid item xs={12} sm={6} md={6} lg={6} style={{ width: '50%', justifyItems: 'center' }} className="border-right-css">
                                      <Box className="column column_border">
                                        <Typography component="h3">
                                          Prescriber: <br></br>
                                          {order?.doctor ? (
                                            <span>
                                              {order?.doctor?.firstName || ''} {order?.doctor?.lastName || ''}
                                            </span>
                                          ) : null}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} style={{ width: '50%' }}>
                                      <Box className="column_last">
                                        <Typography component="h3">
                                          Patient: <br></br>
                                          {order?.doctor ? (
                                            <span>
                                              {order?.patient?.firstName || ''} {order?.patient?.lastName || ''}
                                            </span>
                                          ) : null}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Stack>
                                <Stack direction="row">
                                  <Grid container className="order_general_row order_general_row_bottom">
                                    <Grid item xs={12} sm={6} md={6} lg={6} style={{ width: '50%', justifyItems: 'center' }} className="border-right-css">
                                      <Box className="column column_border" style={{ paddingBottom: '10px' }}>
                                        <Typography component="h3">
                                          order # <br></br>
                                          <span>{order?.order?.invoiceNumber || ''}</span>
                                        </Typography>
                                      </Box>
                                      <Box className="column column_border">
                                        <Typography component="h3">
                                          Tracking # <br></br>
                                          <span>{order?.order?.trackingNumber || ''}</span>
                                        </Typography>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} style={{ width: '50%' }}>
                                      <Box className="column_last">
                                        <Typography component="h3">
                                          Date Issued: <br></br>
                                          <span>{moment(order?.order?.date).format('MM/DD/YYYY')}</span>
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </Box>
                            </Grid>
                          </Grid>
                        </Stack>
                        <Grid container className="prescription_address">
                          <Grid item xs={5} sm={5} md={5} lg={5} style={{ justifyItems: 'center' }}>
                            <Box className="prescription_address_shipping">
                              <Typography component="h3">
                                Shipping: <br></br>
                                {order?.shippingAddress ? (
                                  <span>
                                    {/* {order?.shippingAddress?.ship_name || ''} <br></br> */}
                                    {order?.shippingAddress?.address1 || ''} {order?.shippingAddress?.address2 || ''} <br></br>
                                    {order?.shippingAddress?.city || ''} {order?.shippingAddress?.state || ''} {order?.shippingAddress?.zipCode || ''} <br></br>
                                  </span>
                                ) : null}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={5} sm={5} md={5} lg={5} style={{ justifyItems: 'center' }}>
                            <Box className="prescription_address_billing">
                              <Typography component="h3">
                                Billing: <br></br>
                                {order?.billingAddress ? (
                                  <span>
                                    {/* {order?.billingAddress?.Bill_Name || ''} <br></br> */}
                                    {order?.billingAddress?.address1 || ''} {order?.billingAddress?.address2 || ''} <br></br>
                                    {order?.billingAddress?.city || ''} {order?.billingAddress?.state || ''} {order?.billingAddress?.zipCode || ''} <br></br>
                                  </span>
                                ) : null}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        {/* <Box className="personal_details personal_details_top ">
                          <Typography component="h1">
                            <span className="profile_icon">
                              <img src={Usersvg} alt="Imprimis RX A Harrow Company" width={16} />
                            </span>
                            patient details
                          </Typography>
                        </Box> */}
                        {/* <TableContainer component={Paper} className="table_customizedrx">
                          <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead className="table_head">
                              <TableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Date of birth</StyledTableCell>
                                <StyledTableCell>Address</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody className="table_body">
                              {order?.patient ? (
                                <StyledTableRow>
                                  <StyledTableCell component="td">
                                    <Stack>
                                      {order?.patient?.firstName || ''} {order?.patient?.lastName || ''}
                                    </Stack>
                                  </StyledTableCell>
                                  <StyledTableCell component="td">
                                    <Stack>{moment(order?.patient?.dateOfBirth).format('MM/DD/YYYY')}</Stack>
                                  </StyledTableCell>
                                  <StyledTableCell component="td">
                                    <Stack>
                                      {order?.patient?.address?.address1 || ''} {order?.patient?.address?.city || ''} {order?.patient?.address?.state || ''} {order?.patient?.address?.zipCode || ''}
                                    </Stack>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ) : null}
                            </TableBody>
                          </Table>
                        </TableContainer> */}
                        <Box className="personal_details">
                          <Typography component="h1">
                            <span className="profile_icon">
                              <img src={Rx_icon} alt="Imprimis RX A Harrow Company" width={13} />
                            </span>
                            Medication Details
                          </Typography>
                        </Box>
                        <TableContainer component={Paper} className="table_customizedrx">
                          <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead className="table_head">
                              <TableRow>
                                {/* <StyledTableCell>No</StyledTableCell> */}
                                <StyledTableCell>Image</StyledTableCell>
                                <StyledTableCell>Medication name</StyledTableCell>
                                <StyledTableCell>Dosing Instructions</StyledTableCell>
                                <StyledTableCell>Quantity</StyledTableCell>
                                <StyledTableCell>Refills</StyledTableCell>
                                <StyledTableCell>Price ($)</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody className="table_body">
                              {order?.prescriptions &&
                                order?.prescriptions?.length > 0 &&
                                order?.prescriptions.map((prescription: any, index: number) => (
                                  <StyledTableRow key={index}>
                                    {/* <StyledTableCell component="td">
                                      <Stack>{index + 1}</Stack>
                                    </StyledTableCell> */}
                                    <StyledTableCell component="td" className="plusIcon table_product">
                                      {/* <img src={`https://www.imprimisrx.com/s/sfsites/c/cms/delivery/media/${prescription.contentKey}`} height={120} alt="logo" /> */}
                                      <img src={!prescription?.contentKey ? `https://www.imprimisrx.com/s/sfsites/c/cms/delivery/media/${prescription?.contentKey}` : placeHolder} height={120} alt="logo" />

                                    </StyledTableCell>
                                    <StyledTableCell component="td">
                                      {/* <Stack>{medicationTableObject[prescription?.ndc?.replaceAll('-', '')]?.medicationName}</Stack> */}
                                      <Stack>{prescription?.name || ''}</Stack>
                                    </StyledTableCell>
                                    <StyledTableCell component="td">
                                      <Stack>{prescription?.dosageInstructions || ''}</Stack>
                                    </StyledTableCell>
                                    <StyledTableCell component="td">
                                      <Stack className="table_text_center" style={{ textTransform: 'uppercase' }}>
                                        {prescription?.quantity ? prescription?.quantity + ' ml' : ''}
                                      </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell component="td">
                                      <Stack className="table_text_center">{prescription?.originalRefill || ''}</Stack>
                                    </StyledTableCell>
                                    <StyledTableCell component="td">
                                      <Stack className="table_text_center">{prescription?.price && order?.order?.showPrice ? `$${thousandsSaparator(prescription?.price.toFixed(2))}` : '--'}</Stack>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {order?.order?.showPrice && (
                          <Stack className="qty_box_outer" alignItems="flex-end">
                            <Box className="qty_box">
                              <Box className="total" sx={{ width: '100%', textAlign: 'center' }}>
                                Order Total: ${thousandsSaparator(order?.order?.totalPrice.toFixed(2))}
                              </Box>
                            </Box>
                          </Stack>
                        )}
                      </Box>
                    </Box>
                    <Grid
                      container
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        p: 2,
                        paddingLeft: '7.3rem',
                        paddingRight: '7.3rem'
                      }}
                      spacing={1}
                    >
                      {order?.order?.isPayable && order?.order?.showPrice && (
                        <>
                          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                            <PrimaryButton
                              label={'Edit Shipping Address'}
                              onClick={() => {
                                handleEditOrderAddress();
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                            <PrimaryButton
                              label={'Confirm And Pay'}
                              onClick={() => {
                                handlePayInvoice(order);
                              }}
                            />
                          </Grid>
                        </>
                      )}
                      {order?.order && (
                        <>
                          {order.prescriptions.some((items) => items.originalRefill >= '1' && !items.expired) && (
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                              <PrimaryButton label={'Auto Refill Management'} onClick={() => handleEnrollAutoRefill(order)} />
                            </Grid>
                          )}

                          {order.prescriptions.some((item) => item.refillLeft >= '1' && !item.expired) && (
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                              <PrimaryButton label={'Request Refill'} onClick={handleRequestRefill} />
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                  </Container>
                  {order && Object.keys(order) && (
                    <div style={{ display: 'none' }}>
                      <div ref={componentRef}>
                        <GeneratePdf order={order} />
                      </div>
                    </div>
                  )}

                  <Box className="chat-floating-icon">
                    <img src={Chat} alt="logo" height={65} width={65} />
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
        </Box>
      </Stack>
    </>
  ) : (
    <></>
  );
};

export default OrderPage;
