import { useState } from 'react';
import { Box, Button, Container, DialogContent, DialogTitle, Grid, IconButton, Stack, TextField, Typography, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitleProps } from '../../../interfaces/DialogTitleProps';
import Home from '../../../assets/icons/shipping_home.svg';
import { AxiosResponse } from 'axios';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import PrimaryButton from '../../../core/buttons/primary-button';
import Patient from '../../../constants/grx-api';
import { UPDATE_EXPECTED_DATE } from '../../../constants/Endpoints';
import { toast } from 'react-toastify';
import { formatMessage } from '../../../constants/util';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import holidaysData from '../../../JSON/holidays.json';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const CalanderContainer = (props: { className?: string; children: any }) => {
  return (
    <Box className={props?.className} sx={{ backgroundColor: '#fffff' }}>
      <div style={{ position: 'relative', backgroundColor: '#fffff !important', opacity: 'none' }}>{props.children}</div>
    </Box>
  );
};

export const OrderDeliveryDate = (props: { handleClose: (res?: boolean) => void; orderId: Number; handleOpenDeliveryAddress: (res: String) => void }) => {
  const currentDate = dayjs().format('YYYY-MM-DD'); // Current date in 'YYYY-MM-DD' format
  // Function to add business days, skipping weekends using dayjs
  const holidays = holidaysData.holidays;

 
  const addBusinessDays = (date: any, daysToAdd: any, holidays: string[]) => {
    let result = dayjs(date);
    let addedDays = 0;
  
    while (addedDays < daysToAdd) {
      result = result.add(1, 'day');
      const year = result.year();
      const month = result.month() + 1;  // Months are 0-based in Day.js
      const dayOfMonth = result.date();
  
      const isHoliday = holidays.some((holiday) => {
        const [holidayYear, holidayMonth, holidayDay] = holiday.split('-');
        return (
          parseInt(holidayYear, 10) === year &&  // Match the year
          parseInt(holidayMonth, 10) === month && // Match the month
          parseInt(holidayDay, 10) === dayOfMonth // Match the day
        );
      });
  
      // Skip Saturday (6) and Sunday (0)
      if (result.day() !== 0 && result.day() !== 6 && !isHoliday) {
        addedDays++;
      }
    }
  
    return result;
  };
  

  // Add 8 business days, skipping weekends
  const dateAfter8BusinessDays = addBusinessDays(dayjs(), 8, holidays).format('YYYY-MM-DD');

  // Set the initial state with the calculated date
  const [selectedDate, setSelectedDate] = useState(dateAfter8BusinessDays);
  const handleUpdateDeliveryDate = async () => {
    // try {
    //   const data = {
    //     orderId: props.orderId,
    //     expectedBy: selectedDate
    //   };
    //   const response = await Patient.post(UPDATE_EXPECTED_DATE, data);
    //   if (response.status === 201) {
    //     props.handleClose(true);
    //   }
    // } catch (error: any) {
    //   if (error.response.data.message) {
    //     toast(formatMessage(error.response.data.message));
    //     return;
    //   }
    // }
    props.handleClose(false);
    props.handleOpenDeliveryAddress(selectedDate);
  };

  const onDateChange = (date: any) => {
    setSelectedDate((prev) => date);
  };

  function parseDateUTC(dateString: string | Date) {
   
    if (typeof dateString === 'string') {
      const parts = dateString.split('-');
      const year = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10); // Months are zero-indexed
      const day = parseInt(parts[2], 10);
  
      // Construct the date in UTC explicitly
      const dateObj = new Date(Date.UTC(year, month - 1, day));
  
      return dateObj;
    }
    
    return dateString;
  }

  // Function to add business days skipping weekends
  const addDaysSkippingWeekends = (date: Date, daysToAdd: number, holidays: string[]): Date => {
    let result = new Date(date);
    let addedDays = 0;

    while (addedDays < daysToAdd) {
      result.setDate(result.getDate() + 1);  // Increment the day by 1

      const year = result.getFullYear();
      const month = result.getMonth() + 1;  // Months are 0-based in JS
      const dayOfMonth = result.getDate();

      // Convert holidays to a Set for efficient lookups (same format as "YYYY-MM-DD")
      const isHoliday = holidays.some((holiday) => {
        const [holidayYear, holidayMonth, holidayDay] = holiday.split('-');
        return (
          parseInt(holidayYear, 10) === year &&  // Match the year
          parseInt(holidayMonth, 10) === month && // Match the month
          parseInt(holidayDay, 10) === dayOfMonth // Match the day
        );
      });

      // Skip weekends (Saturday = 6, Sunday = 0) and holidays
      if (result.getDay() !== 0 && result.getDay() !== 6 && !isHoliday) {
        addedDays++;
      }

      console.log(result.getDay() !== 0, result.getDay() !== 6, !isHoliday, addedDays)
    }

    return result;
  };

  return (
    <>
      <DialogContent dividers className="popup_content" style={{ minHeight: '55%' }}>
        <Box component="main" className="card-info">
          <Container maxWidth="lg">
            <Box className="main-box" mt={4}>
              <Box sx={{ bgcolor: 'background.paper' }}>
                <Stack className="modal_heading_main" direction="row" justifyContent="center" alignItems="center" mt={3}>
                  <Typography className="heading_bottom_without_border">
                    {/* <span className="profile_icon">
                      <img src={Home} alt="Imprimis RX A Harrow Company" width={16} />
                    </span> */}
                    Order Expected Date
                  </Typography>
                </Stack>
                <Box>
                  <Grid container spacing={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Grid item xl={10} lg={10} md={10} sm={10} xs={10} mt={1} display={'flex'} justifyContent={'center'}>
                      <DatePicker
                        selected={parseDateUTC(selectedDate)}
                        onChange={(date) => onDateChange(date)}
                        className="centered-text"
                        closeOnScroll={true}
                        dateFormat="MM-dd-yyyy"
                        minDate={addDaysSkippingWeekends(new Date(), 8, holidays)} // Use function to skip weekends and set minDate
                        calendarContainer={CalanderContainer}
                        showMonthDropdown
                        dropdownMode="select"
                        filterDate={(date) => {
                          const day = date.getDay();
                          const isWeekend = day === 0 || day === 6; // Check for weekends

                          // Extract month and date from the given date
                          const year = date.getFullYear();
                          const month = date.getMonth() + 1; // Months are 0-based in JS
                          const dayOfMonth = date.getDate();

                          // Check if this month/date matches any holiday
                          const isHoliday = holidays.some((holiday) => {
                            const [holidayYear, holidayMonth, holidayDay] = holiday.split('-');
                            return parseInt(holidayYear, 10) === year && parseInt(holidayMonth, 10) === month && parseInt(holidayDay, 10) === dayOfMonth;
                          });

                          return !isWeekend && !isHoliday; // Only allow weekdays and non-holidays
                        }}
                        customInput={
                          <InputMask
                            mask="99-99-9999"
                            maskChar=" "
                            disabled={false}
                            value={new Date().toLocaleDateString()} // Convert to match MM-DD-YYYY format
                            onChange={(e: any) => onDateChange(e)}
                          >
                            {(inputProps: any) => (
                              <TextField
                                {...inputProps}
                                label="Please select date"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <CalendarMonthIcon />
                                    </InputAdornment>
                                  )
                                }}
                                InputLabelProps={{ required: true }}
                                inputProps={{
                                  ...inputProps.inputProps,
                                  autoComplete: 'new-password'
                                }}
                                fullWidth
                              />
                            )}
                          </InputMask>
                        }
                        wrapperClassName="date-picker-wrapper form-input" // Add wrapper class if needed
                        popperPlacement="bottom" // Customize popper placement if needed
                      // inline
                      />
                    </Grid>
                    <Grid item xl={10} lg={10} md={10} sm={10} xs={10} mt={1} display={'flex'} justifyContent={'center'}>
                      <PrimaryButton label={'Next'} onClick={handleUpdateDeliveryDate} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} display={'flex'} justifyContent={'center'}>
                    <Grid item xl={10} lg={10} md={10} sm={10} xs={10} mt={1} display={'flex'} justifyContent={'center'}>
                      <Typography variant="subtitle2">Please indicate the date you would like to receive your medication by. If no date is chosen, it will default to 7 business days from today. If choosing a date earlier than 7 days, additional shipping charges may apply.</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </DialogContent>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={() => props.handleClose(false)}></BootstrapDialogTitle>
    </>
  );
};
