import React, { useRef } from 'react';
import { Box, Container, Stack, Grid, Typography, Button } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import Back from '../../../assets/icons/back_arrow.svg';
import Chat from '../../../assets/icons/chat.svg';
import Ordersvg from '../../../assets/icons/order.svg';
import Paper from '@mui/material/Paper';
import Pdf from '../../../assets/icons/view-pdf.svg';
import PrimaryButton from '../../../core/buttons/primary-button';
import Product from '../../../assets/icons/table_product.svg';
import Rx from '../../../assets/icons/RX.svg';
import Rx_icon from '../../../assets/icons/rx_icon.svg';
import SecondaryButton from '../../../core/buttons/secondary-button';
import Usersvg from '../../../assets/icons/user.svg';
import { Order } from '../../../models/Order';
import placeHolder from '../../../assets/placeHolder.png';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const GeneratePdf = (props: { order: Order }) => {
  const { order } = props;

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <Box component="main" className="pdf_genral_order">
      <Box className="pdf-main-content-wrapper-full">
        <Container maxWidth="xl" ref={componentRef}>
          <Box className="pdf-main-box">
            <Container maxWidth="xl">
              <Box className="pdf_order_general">
                <Box>
                  <Stack direction="row">
                    <Grid container>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Box className="pdf_rx_img">
                          <img src={Rx} alt="Imprimis RX A Harrow Company" />
                        </Box>
                      </Grid>
                      <Grid item xs={7} sm={7} md={7} lg={7}>
                        <Box className="pdf_order_general_info">
                          <Box className="pdf_personal_details">
                            <Typography className="pdf_heading">
                              <span className="pdf_profile_icon">
                                <img src={Ordersvg} alt="Imprimis RX A Harrow Company" width={16} />
                              </span>
                              <span className="pdf_profile_icon_heading">Prescription Info</span>
                            </Typography>
                          </Box>
                          <Stack direction="row">
                            <Grid container className="pdf_prescribe">
                              <Grid item xs={6} sm={6} md={6} lg={6}>
                                <Box className="pdf_order_npi">
                                  <Typography component="h3">
                                    Prescriber:<br></br>
                                    {order?.doctor ? (
                                      <span>
                                        {order?.doctor?.firstName || ''} {order?.doctor?.lastName || ''}
                                      </span>
                                    ) : null}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Stack>
                          <Stack direction="row">
                            <Grid container className="pdf_prescribe prescribe_bottom">
                              <Grid item xs={6} sm={6} md={6} lg={6} style={{ width: '50%', justifyItems: 'center' }}>
                                <Box className="pdf_order_npi order_npi_border" style={{ paddingBottom: '10px' }}>
                                  <Typography component="h3">
                                    order # <br></br>
                                    <span>{order?.order?.invoiceNumber || ''}</span>
                                  </Typography>
                                </Box>
                                <Box className="pdf_order_npi order_npi_border" style={{}}>
                                  <Typography component="h3">
                                    Tracking # <br></br>
                                    <span>{order?.order?.trackingNumber || ''}</span>
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6} style={{ width: '50%' }}>
                                <Box className="pdf_order_npi_last">
                                  <Typography component="h3">
                                    Date Issued: <br></br>
                                    <span>{moment(order?.order?.date).format('MM/DD/YYYY')}</span>
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Grid container className="pdf_prescription_address">
                    <Grid item xs={5} sm={5} md={5} lg={5} style={{ justifyItems: 'center' }}>
                      <Box className="pdf_prescription_address_shipping">
                        <Typography component="h3">
                          <div> Shipping:</div> <br></br>
                          {order?.shippingAddress ? (
                            <span>
                              {/* {order?.shippingAddress?.ship_name || ''} <br></br> */}
                              {order?.shippingAddress?.address1 || ''} {order?.shippingAddress?.address2 || ''} <br></br>
                              {order?.shippingAddress?.city || ''} {order?.shippingAddress?.state || ''} {order?.shippingAddress?.zipCode || ''} <br></br>
                            </span>
                          ) : null}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={5} sm={5} md={5} lg={5} style={{ justifyItems: 'center' }}>
                      <Box className="pdf_prescription_address_billing">
                        <Typography component="h3">
                          <div>Billing:</div> <br></br>
                          {order?.billingAddress ? (
                            <span>
                              {/* {order?.billingAddress?.Bill_Name || ''} <br></br> */}
                              {order?.billingAddress?.address1 || ''} {order?.billingAddress?.address2 || ''} <br></br>
                              {order?.billingAddress?.city || ''} {order?.billingAddress?.state || ''} {order?.billingAddress?.zipCode || ''} <br></br>
                            </span>
                          ) : null}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className="pdf_personal_details">
                    <Typography className="pdf_heading" component="h1" pt={0} pb="10px">
                      <span className="pdf_profile_icon">
                        <img src={Usersvg} alt="Imprimis RX A Harrow Company" width={16} />
                      </span>
                      patient details
                    </Typography>
                  </Box>
                  <TableContainer component={Paper} className="pdf_table_customizedrx">
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead className="table_head">
                        <TableRow>
                          <StyledTableCell>Name</StyledTableCell>
                          <StyledTableCell>Date of birth</StyledTableCell>
                          <StyledTableCell>Address</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="table_body">
                        {order?.patient ? (
                          <StyledTableRow>
                            <StyledTableCell component="td">
                              <Stack>
                                {order?.patient?.firstName || ''} {order?.patient?.lastName || ''}
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell component="td">
                              <Stack>{moment(order?.patient?.dateOfBirth).format('MM/DD/YYYY')}</Stack>
                            </StyledTableCell>
                            <StyledTableCell component="td">
                              <Stack>
                                {order?.patient?.address?.address1 || ''} {order?.patient?.address?.city || ''} {order?.patient?.address?.state || ''} {order?.patient?.address?.zipCode || ''}
                              </Stack>
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="pdf_personal_details">
                    <Typography component="h1" pt={0} pb="10px">
                      <span className="profile_icon">
                        <img src={Rx_icon} alt="Imprimis RX A Harrow Company" width={13} />
                      </span>
                      Medication Details
                    </Typography>
                  </Box>
                  <TableContainer component={Paper} className="pdf_medication_table">
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead className="table_head">
                        <TableRow>
                          <StyledTableCell>No</StyledTableCell>
                          <StyledTableCell>Image</StyledTableCell>
                          <StyledTableCell>Medication name</StyledTableCell>
                          <StyledTableCell>Dosing Instructions</StyledTableCell>
                          <StyledTableCell>Quantity</StyledTableCell>
                          <StyledTableCell>Refill Left</StyledTableCell>
                          <StyledTableCell>Price($)</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="pdf_medication_table_body">
                        {order?.prescriptions &&
                          order?.prescriptions?.length > 0 &&
                          order?.prescriptions.map((prescription: any, index: number) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell component="td">
                                <Stack>{index + 1}</Stack>
                              </StyledTableCell>
                              <StyledTableCell component="td" className="plusIcon table_product">
                                <img src={prescription?.contentKey ? `https://www.imprimisrx.com/s/sfsites/c/cms/delivery/media/${prescription?.contentKey}` : placeHolder} height={80} alt="logo" />

                                {/* <img src={`https://www.imprimisrx.com/s/sfsites/c/cms/delivery/media/${prescription.contentKey}`} height={80} alt="logo" /> */}
                              </StyledTableCell>
                              <StyledTableCell component="td">
                                {/* <Stack>{medicationTableObject[prescription?.ndc?.replaceAll('-', '')]?.medicationName}</Stack> */}
                                <Stack>{prescription?.name || ''}</Stack>
                              </StyledTableCell>
                              <StyledTableCell component="td">
                                <Stack>{prescription?.dosageInstructions || ''}</Stack>
                              </StyledTableCell>
                              <StyledTableCell component="td">
                                <Stack className="table_text_center" style={{ textTransform: 'uppercase' }}>
                                  {prescription?.quantity ? prescription?.quantity + ' ml' : ''}
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell component="td">
                                <Stack className="table_text_center">{prescription?.refillLeft || ''}</Stack>
                              </StyledTableCell>
                              <StyledTableCell component="td">
                                <Stack className="table_text_center">{order?.order?.showPrice && prescription?.price && `$${prescription?.price.toFixed(2)}`}</Stack>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {order?.order?.showPrice && (
                    <Stack className="pdf_qty_box_outer" alignItems="flex-end">
                      <Box className="pdf_qty_box">
                        <Box className="pdf_total" sx={{ width: '100%', textAlign: 'center' }}>
                          {' '}
                          Order Total: ${order?.order?.totalPrice.toFixed(2)}
                        </Box>
                      </Box>
                    </Stack>
                  )}
                </Box>
              </Box>
            </Container>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default GeneratePdf;
